export const firebaseConfig = {
	apiKey: 'AIzaSyCv7jvtvUV49HIK-kYRm28yw7ngeErZzTk',
	authDomain: 'revenuewell.firebaseapp.com',
	databaseURL: 'https://revenuewell-default-rtdb.firebaseio.com',
	projectId: 'revenuewell',
	storageBucket: 'revenuewell.appspot.com',
	messagingSenderId: '150317732803',
	appId: '1:150317732803:web:dc753dd4e94ab45bc12376',
	functionsUrl: 'https://us-central1-revenuewell.cloudfunctions.net',
};

export const env: 'prod' | 'beta' | 'test' = 'test';
